<template>
   <div class="cont not-check">
      <!-- 面包屑 -->
      <el-row class="crumbs-box">
         <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>AIOT</el-breadcrumb-item>
            <el-breadcrumb-item>家居设备</el-breadcrumb-item>
            <el-breadcrumb-item>设备列表</el-breadcrumb-item>
            <el-breadcrumb-item>{{ this.$route.fullPath | fullPathEvent }}</el-breadcrumb-item>
         </el-breadcrumb>
      </el-row>
      <el-row class="content-box">
         <!-- 表头 -->
         <el-row class="search-box">
            <el-row class="search-row">
               <el-row class="search-item">
                  <label>会员号：</label>
                  <el-select  class="width-220" v-model="userId" @change="changeMember" :placeholder="$t('msg.select')">
                     <el-option v-for="item in memberList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                  </el-select>
               </el-row>
               <el-row class="search-item">
                  <label>设备编码：</label>
                  <el-input  class="width-220" v-model="deviceNameQ" :placeholder="$t('msg.dev_number')" clearable></el-input>
               </el-row>
               <el-row class="search-item">
                  <label>在线状态：</label>
                  <el-button-group class="m-right-10">
                     <el-button :class="state === 'ONLINE' ? 'bg-gradient' : ''" @click="state = 'ONLINE'" ><span v-text="$t('msg.online')">在线</span></el-button>
                     <el-button :class="state === 'OFFLINE' ? 'bg-gradient' : ''" @click="state = 'OFFLINE'" ><span v-text="$t('msg.offline')">离线</span></el-button>
                     <el-button :class="state === 'UNACTIVE' ? 'bg-gradient' : ''" @click="state = 'UNACTIVE'" ><span v-text="$t('msg.inactivated')">未激活</span></el-button>
                  </el-button-group>
               </el-row>
               <el-button class="bg-gradient"  type="primary" icon="el-icon-search" @click="handleQuery(true)"><span v-text="$t('msg.search')">搜索</span></el-button>
            <el-button  type="primary" plain icon="el-icon-refresh-right" @click="handleQuery(false)"><span v-text="$t('msg.reset')">重置</span></el-button>
            </el-row>


         </el-row>
         <!-- 表格 -->
         <div class="table-box">
            <el-table
                  v-loading="loading"
                  :data="tableData"
                  highlight-current-row
                  row-key="id"
                  lazy
                  :load="load"
                  :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
                  style="width: 100%"
                  :stripe="true"
                  border fit
                  >
               <el-table-column :label="$t('msg.number')" width="80">
                  <template slot-scope="scope">
                     {{scope.$index + 1}}
                  </template>
               </el-table-column>
               <el-table-column prop="deviceName" :label="$t('msg.dev_name')" min-width="160"></el-table-column>
               <el-table-column prop="versionsName" :label="$t('msg.dev_type')" :min-width="typeWidth"></el-table-column>
               <el-table-column :label="$t('msg.status')" min-width="100">
                  <template slot-scope="scope">
                     <span class="box-span mini bg-green" v-if="scope.row.state === 'ONLINE'">在线</span>
                     <span class="box-span mini bg-red" v-else-if="scope.row.state === 'OFFLINE'">离线</span>
                     <span class="box-span mini bg-gray" v-else>未知</span>
                  </template>
               </el-table-column>
               <el-table-column prop="userName" label="会员号" min-width="160"></el-table-column>
               <el-table-column :label="$t('msg.install_location')">
                  <template slot-scope="scope">
                     {{ scope.row.location | filterLocation(that) }}
                  </template>
               </el-table-column>
               <el-table-column prop="lastOnlineTime" :label="$t('msg.last_online_time')" min-width="180" sortable></el-table-column>
               <el-table-column :label="$t('msg.operating')" :min-width="operWidth" fixed="right">
                  <template slot-scope="scope">
                     <el-button  type="text" icon="el-icon-warning-outline" @click="handleDetail(scope.row)" >
                        <el-link type="primary" v-text="$t('msg.detail')">详情</el-link>
                     </el-button>
                     <el-button  type="text" icon="el-icon-edit" @click="handleEdit(scope.row)" >
                        <el-link type="primary" v-text="$t('msg.edit')">编辑</el-link>
                     </el-button>
                     <el-button v-if="scope.row.deviceTypeId !== '100'" type="text" style="color: #F56C6C;" icon="el-icon-delete" @click="handleDel(scope.row.id)" >
                        <el-link type="danger" v-text="$t('msg.remove')">删除</el-link>
                     </el-button>
                     <el-button
                           v-if="scope.row.state === 'OFFLINE' &&
                                 scope.row.deviceTypeId !== '100' &&
                                 scope.row.productId.indexOf('WF') === -1"
                           @click="handleAwaken(scope.row.parentId)"
                           type="text" >
                        <el-link type="success" plain v-text="$t('msg.wake')">一键唤醒</el-link>
                     </el-button>
                     <!-- 智能锁 -->
                     <el-button
                           v-if="scope.row.deviceTypeId === '110'&&
                                 scope.row.state === 'ONLINE'"
                           @click="setLock(scope.row)"
                           type="text" >
                        <el-link type="primary" plain v-text="$t('msg.lock')">开锁</el-link>
                     </el-button>
                     <!-- 温控面板 -->
                     <el-button
                           v-if="scope.row.deviceTypeId === '112'"
                           @click="getTemp(scope.row)"
                           type="text"
                           >
                        <el-link type="primary" plain v-text="$t('msg.manipulation')">操控</el-link>
                     </el-button>
                     <!-- 开关面板 -->
                     <el-button
                           v-if="scope.row.state === 'ONLINE' &&
                                 scope.row.deviceTypeId === '101' &&
                                 scope.row.productId.substring(6, 8) !== '11'"
                           @click="getSwitch(scope.row)" type="text" >
                        <el-link type="primary" plain v-text="$t('msg.manipulation')">操控</el-link>
                     </el-button>
                     <!-- 调光面板 -->
                     <el-button
                           v-if="scope.row.state === 'ONLINE' &&
                                 scope.row.productId.substring(0, 8) === 'HOSWZB11'"
                           @click="getDimming(scope.row)" type="text" >
                        <el-link type="primary" plain v-text="$t('msg.manipulation')">操控</el-link>
                     </el-button>
                     <!-- 场景面板 -->
                     <el-button
                           v-if="scope.row.state === 'ONLINE' &&
                                 scope.row.deviceTypeId === '102'"
                           type="text" icon="" >
                        <el-link type="primary" plain></el-link>
                     </el-button>
                     <!-- 窗帘电机 -->
                     <el-button
                           v-if="scope.row.state === 'ONLINE' &&
                                 scope.row.deviceTypeId === '108'"
                           @click="getCurtainMotor(scope.row)" type="text" icon="" >
                        <el-link type="primary" plain v-text="$t('msg.manipulation')">操控</el-link>
                     </el-button>
                     <!-- wifi插座 -->
                     <el-button
                           v-if="scope.row.state === 'ONLINE' &&
                                 scope.row.deviceTypeId === '103'"
                           @click="getSocket(scope.row)" type="text" icon="" >
                        <el-link type="primary" plain v-text="$t('msg.manipulation')">操控</el-link>
                     </el-button>
                  </template>
               </el-table-column>
            </el-table>
            <!-- 分页 -->
            <pagination :total="total" :page-size="limit" @handleSizeChangeSub="changePageNum" @handleCurrentChangeSub="page"/>
         </div>
         <!-- 开关面板 -->
         <el-form label-width="105px">
            <el-dialog :title="$t('msg.change_status')" :visible.sync="dialogSwitch" width="600px">
               <el-row class="m-bottom-15" v-for="(item, index) in switchList" :key="index">
                  <el-col :span="6" class="t-right"><span class="l-height-26">第{{index + 1}}位别名：</span></el-col>
                  <el-col :span="6">
                     <p class="l-height-26">{{item.alias || '-'}}</p>
                  </el-col>
                  <el-col :span="6" class="t-right"><span class="l-height-26">第{{index + 1}}位状态：</span></el-col>
                  <el-col :span="6" class="l-height-26">
                     <el-switch
                           @change="setSwitch(item.state, index)"
                           style="margin: 0px 5px;"
                           v-model="item.state"
                           active-value="TURNON"
                           inactive-value="TURNOFF"
                           active-color="#67c23a"
                           inactive-color="#dcdfe6">
                     </el-switch>
                  </el-col>
               </el-row>
            </el-dialog>
         </el-form>
         <!-- 调光面板 -->
         <el-form label-width="105px">
            <el-dialog title="控制亮度" :visible.sync="dialogDimming" width="600px">
               <el-row class="m-bottom-15">
                  <el-col :span="7" class="t-a-right" v-text="$t('msg.operating_a')">操作：</el-col>
                  <el-col :span="17">
                     <el-slider
                           class="p-left-10"
                           @change="setDimming"
                           v-model="currentLevel"
                           :min="1"
                           :max="254">
                     </el-slider>
                  </el-col>
               </el-row>
            </el-dialog>
         </el-form>
         <!-- 窗帘电机 -->
         <el-form label-width="105px" style="line-height: 26px;">
            <el-dialog :title="$t('msg.curtain_open_close_socket')" :visible.sync="dialogCurtain" width="510px">
               <div class="config-box">
                  <el-row class="m-bottom-15">
                     <el-col :span="7" class="t-a-right" v-text="$t('msg.operating_a')">操作：</el-col>
                     <el-col :span="17">
                        <el-radio-group @change="setCurtainMotor" v-model="curtainState">
                           <el-radio label="TURNON">打开</el-radio>
                           <el-radio label="TURNOFF">关闭</el-radio>
                           <el-radio label="PAUSE">停止</el-radio>
                        </el-radio-group>
                     </el-col>
                  </el-row>
               </div>
            </el-dialog>
         </el-form>
         <!-- WIFI插座 -->
         <el-form label-width="105px" style="line-height: 26px;">
            <el-dialog :title="$t('msg.wifi_open_close_socket')" :visible.sync="dialogSocket" width="510px">
               <div class="config-box">
                  <el-row class="m-bottom-15">
                     <el-col :span="7" class="t-a-right" v-text="$t('msg.operating_a')">操作：</el-col>
                     <el-col :span="17">
                        <el-switch
                              v-model="socketState"
                              @change="setSocket"
                              active-value="TURNON"
                              inactive-value="TURNOFF">
                        </el-switch>
                     </el-col>
                  </el-row>
               </div>
            </el-dialog>
         </el-form>
         <!-- WIFI温控面板 -->
         <el-form label-width="105px" style="line-height: 26px;">
            <el-dialog :title="$t('msg.wifi_open_close_tip')" :visible.sync="dialogTemp" width="510px">
               <div class="config-box">
                  <el-row class="m-bottom-15">
                     <el-col :span="7" class="t-a-right" v-text="$t('msg.operating_a')">操作：</el-col>
                     <el-col :span="17">
                        <el-switch v-model="onOffState" @change="setTemp" active-value="TURNON" inactive-value="TURNOFF"></el-switch>
                     </el-col>
                  </el-row>
               </div>
            </el-dialog>
         </el-form>
      </el-row>

   </div>
</template>
<script>
   import { mapState } from 'vuex'
   import { urlObj } from '@/api/interface'
   import {getDict} from '@/common/js/common'
   export default {
      name: 'homeAll',
      data () {
         return {
            that: this,
            tableData: [],        // 设备列表
            memberList: [],       // 会员列表
            loading: true,        // 表格加载效果
            total: 0,             // 表格数据总数
            dialogTemp: false,    // 温控面板弹窗
            dialogCurtain: false, // 窗帘电机弹窗
            dialogSocket: false,  // 插座弹窗
            dialogSwitch: false,  // 开关弹窗
            dialogDimming: false, // 调光面板弹窗
            operWidth: 0,         // 操作栏宽度
            typeWidth: 0,         // 类型栏宽度
            /* 查询设备 */
            deviceNameQ: '',      // 设备名称
            state: '',            // 在线状态
            userId: '',           // 会员ID
            limit: 0,             // 每页数
            page: 1,              // 当前页
            /* 设置设备 */
            id: '',               // 设备ID
            deviceName: '',       // 设备名称
            onOffState: '',       // 温控面板状态
            curtainState: '',     // 窗帘电机状态
            socketState: '',      // 插座状态
            switchList: [],       // 开关状态列表
            currentLevel: 0,      // 调光面板亮度
            /* 提示文本 */
            confirm: '确定',
            unable_to_wake: '该设备没有加入网关，无法唤醒！',
            instruction_has_been_issued: '指令已发出，请稍后...',
            open_command_has_been_issued: '设备打开指令已发出，请稍后...',
            close_command_has_been_issued: '设备关闭指令已发出，请稍后...',
            wake_up_command_has_been_issued: '设备唤醒指令已发出,请观察该网关下面的设备状态！',
            update_success: '修改成功！',
            operate_success: '操作成功！',
            confirm_remove: '确定移除？',
            remove_success: '移除成功！',
            own_hotel_no_null: '所属酒店不能为空！',
            floor_no_null: '楼层不能为空！',
            dev_location_no_null: '设备位置不能为空！',
            room_no_null: '房间号不能为空！',
            confirm_wake_txt: '若唤醒该设备，将会重启该房间的网关，属于该房间的所有设备将会被重启，确定唤醒？',
         }
      },
      computed: {
         ...mapState(['dictData'])
      },
      beforeRouteUpdate(to, from, next){
         this.$store.dispatch('setCachePage', 'fakePage')
         this.handleQuery(false)
         next()
      },
      beforeRouteLeave(to, from, next){
         if (to.name === 'homeDeviceEdit' || to.name === 'homeDeviceDetail'){
            this.$store.dispatch('setCachePage', from.name)
            next()
            return
         }
         this.$store.dispatch('setCachePage', 'fakePage')
         next()
      },
      mounted () {
         this.limit = sessionStorage.getItem('pageSize') * 1
         getDict(['device-location'])
         // this.getMembers()
         this.getDeviceList()
      },
      inject: ['reload'],
      methods: {
         // 获取会员列表
         /*getMembers() {
            const url = urlObj.members
            this.$axios.post_query(url, {}).then(res => {
               if (res.success) {
                  let memberList = res.records
                  this.memberList = memberList.map(item => {
                     return {value: item.id, label: item.userName}
                  })
                  this.getDeviceList()
               }
            }).catch(error => {
               this.$message.error(error)
            })
         },*/
         // 获取设备列表
         getDeviceList() {
            let param = {
               userId: this.userId,
               deviceName: this.deviceNameQ,
               state: this.state
            }
            const typeCode = this.$route.params.device_type_id
            if (typeCode === 'all') {
               param.productIds = ['HOTCW', 'HOINW', 'HOSOW', 'HOGWZ', 'HODRW']
            }else {
               param.typeCode = typeCode
            }
            const url = urlObj.devHomePageForPc + `?limit=${this.limit}&page=${this.page}`
            this.$axios.post(url, param, 'json').then(async res => {
               if (res.success) {
                  this.loading = false
                  this.total = res.total
                  let tableData = res.records
                  if (this.total === 0) return this.tableData = []
                  tableData.forEach(item => item.leaf && (item.hasChildren = true))
                  this.tableData = tableData
               }
            })
         },
         // 获取设备子节点列表
         load(tree, treeNode, resolve) {
            const url = urlObj.getHomeByGatewayId
            const param = { parentId: tree.id }
            this.$axios.post(url, param).then(res => {
               if (res.success) {
                  if (res.success) {
                     let tableData = res.records
                     resolve(tableData)
                  }
               }
            }).catch(error => {
               this.$message.error(error)
            })
         },
         // 改变当前选中会员
         changeMember(val) {
            this.userId = val
            this.getBuildList()
         },
         // 查询设备列表
         handleQuery(fool){
            if (fool) return this.getDeviceList()
            this.userId = ''
            this.deviceName = ''
            this.state = ''
         },
         // 改变每页数
         changePageNum(num) {
            this.limit = num
            this.getDeviceList()
         },
         // 改变当前页
         changeCurrPage(num) {
            this.page = num
            this.getDeviceList()
         },
         // 查看详情
         handleDetail(row) {
            this.$router.push({
               path: '/home_device_detail',
               query: {
                  id: row.id,
                  type: 2,
                  userName: row.userName,
                  location: row.location
               }
            })
         },
         // 编辑设备
         handleEdit(row) {
            this.$router.push({
               path: '/home_device_edit',
               query: {
                  id: row.id,
                  type: 2,
               }
            })
         },
         // 删除设备
         handleDel(deviceId) {
            this.$confirm(this.confirm_remove, this.prompt, {
               confirmButtonText: this.confirm,
               cancelButtonText: this.cancel,
               type: 'error'
            }).then(() => {
               const url = urlObj.deleteHomeDeviceById
               const param = { deviceId }
               this.$axios.post(url, param).then(res => {
                  if (res.success) {
                     this.$message({
                        showClose: true,
                        message: this.remove_success,
                        type: 'success'
                     })
                     this.getDeviceList()
                  }
               }).catch(error => {
                  this.$message.error(error)
               })
            }).catch(cancel => {
               console.log(cancel)
            })
         },
         // 一键唤醒
         handleAwaken(parentId) {
            this.$confirm(this.confirm_wake_txt, this.prompt, {
               confirmButtonText: this.confirm,
               cancelButtonText: this.cancel,
               type: 'error'
            }).then(() => {
               if (!parentId) { return this.$message(this.unable_to_wake) }
               const url = urlObj.devicWakeUp
               const param = { gatewayId: parentId }
               this.$axios.get(url, param).then(res => {
                  if (res.success) {
                     this.$message({
                        showClose: true,
                        message: this.wake_up_command_has_been_issued,
                        type: 'success'
                     })
                  }
               }).catch(error => {
                  this.$message.error(error)
               })
            }).catch(cancel => {
               console.log(cancel)
            })
         },
         // 获取开关面板状态
         getSwitch(row) {
            this.dialogSwitch = true
            this.id = row.id
            this.deviceName = row.deviceName
            const url = urlObj.getHomeDevStatus
            const param = { deviceId: row.id }
            this.$axios.post(url, param).then(res => {
               if (res.success) {
                  let switchList = res.records.switchList
                  this.switchList = switchList.map(item => {
                     return {alias: item.alias, state: item.state}
                  })
               }
            }).catch(error => {
               this.$message.error(error)
            })
         },
         // 设置开关面板
         setSwitch(switchState, indexs) {
            const url = urlObj.setHomeSendIotMsg
            const param = {
               id: this.id,
               deviceName: this.deviceName,
               indexs,
               switchState: switchState,
               operationAction: 'SWITCH'
            }
            this.$axios.post(url, param).then(res => {
               if (res.success) {
                  this.$message({
                     showClose: true,
                     message: this.operate_success,
                     type: 'success'
                  })
               }
            }).catch(error => {
               this.$message.error(error)
            })
         },
         // 获取调光面板
         getDimming(row){
            this.dialogDimming = true
            this.id = row.id
            this.deviceName = row.deviceName
         },
         // 设置调光面板
         setDimming(){
            const url = urlObj.setSendIotMsg
            const param = {
               id: this.id,
               deviceName: this.deviceName,
               currentLevel: String(this.currentLevel),
               onLevel: String(this.currentLevel),
               operationAction: 'DIMMING'
            }
            this.$axios.post(url, param).then(res => {
               if (res.success){
                  this.$message({
                     showClose: true,
                     message: this.operate_success,
                     type: 'success'
                  })
               }
            }).catch(error => {
               this.$message.error(error)
            })
         },
         // 获取温控面板
         getTemp(row) {
            this.dialogTemp = true
            this.id = row.id
            this.deviceName = row.deviceName
            const url = urlObj.getHomeDevTemperature
            const param = { deviceId: row.id }
            this.$axios.post(url, param).then(res => {
               if (res.success) {
                  this.onOffState = res.records.onoff
               }
            }).catch(error => {
               this.$message.error(error)
            })
         },
         // 设置温控
         setTemp() {
            const url = urlObj.setHomeSendIotMsg
            let param = {
               id: this.deviceId,
               deviceName: this.deviceName,
               onOffState: this.onOffState,
               operationAction: 'TEMPPANEL',
            }
            this.$axios.post(url, param).then(res => {
               if (res.success) {
                  this.$message({
                     showClose: true,
                     message: this.operate_success,
                     type: 'success'
                  })
               }
            }).catch(error => {
               this.$message.error(error)
            })
         },
         // 设置智能锁
         setLock(row) {
            const url = urlObj.setHomeSendIotMsg
            const param = {
               id: row.id,
               operationAction: 'UNLOCK',
               deviceName: row.deviceName
            }
            this.$axios.post(url, param).then(res => {
               if (res.success) {
                  this.$message({
                     showClose: true,
                     message: this.instruction_has_been_issued,
                     type: 'success'
                  })
               }
            }).catch(error => {
               this.$message.error(error)
            })
         },
         // 获取窗帘电机
         getCurtainMotor(row) {
            this.id = row.id
            this.deviceName = row.deviceName
            this.dialogCurtain = true
         },
         // 设置窗帘电机
         setCurtainMotor() {
            let url = urlObj.setHomeSendIotMsg
            let param = {
               id: this.id,
               deviceName: this.deviceName,
               indexs: 1,
               curtainState: this.curtainState,
               operationAction: 'CURTAIN'
            }
            this.$axios.post(url, param).then((res) => {
               if (res.success) {
                  this.$message({
                     showClose: true,
                     message: this.close_command_has_been_issued,
                     type: 'success'
                  })
               }
            }).catch(error => {
               this.$message.error(error)
            })
         },
         // 获取插座
         getSocket(row) {
            this.dialogSocket = true
            this.id = row.id
            this.deviceName = row.deviceName
            const url = urlObj.getDevDetail
            const param = { deviceId: row.id }
            this.$axios.post(url, param).then(res => {
               this.socketState = res.records[0].state
            }).catch(error => {
               this.$message.error(error)
            })
         },
         // 设置插座
         setSocket() {
            const url = urlObj.setHomeSendIotMsg
            const param = {
               id: this.id,
               deviceName: this.deviceName,
               switchState: this.socketState,
               indexs: 1,
               operationAction: 'SWITCH'
            }
            this.$axios.post(url, param).then(res => {
               if (res.success) {
                  this.$message({
                     showClose: true,
                     message: this.close_command_has_been_issued,
                     type: 'success'
                  })
                  this.dialogSocket = false
               }
            }).catch(error => {
               this.$message.error(error)
            })
         },
      },
      filters: {
         fullPathEvent(path){
            switch (path){
               case "/home_all/all":
                  return "全部"
               case "/home_all/HOGW":
                  return "网关"
               case "/home_all/HOSW":
                  return "开关面板"
               case "/home_all/HOSC":
                  return "场景面板"
               case "/home_all/HOSO":
                  return "插座"
               case "/home_all/HODS":
                  return "门磁感应器"
               case "/home_all/HOSA":
                  return "烟雾感应器"
               case "/home_all/HOGS":
                  return "红外感应器"
               case "/home_all/HODR":
                  return "窗帘电机"
               case "/home_all/HORA":
                  return "窗帘导轨"
               case "/home_all/HOSL":
                  return "智能锁"
               case "/home_all/HOIN":
                  return "红外宝"
               case "/home_all/HOTC":
                  return "温控面板"
               case "/home_all/HVAC":
                  return "VRV空调面板"
            }
         },
         filterLocation(val, that) {
            if (val && that.dictData['device-location']) return that.dictData['device-location'][val] || ''
         }
      },
      watch: {
         $route(to) {
            if (to.name === 'homeAll'){
               this.getDeviceList()
            }
         }
      }
   }
</script>
<style lang="scss">
   .not-check{
      .el-table__row td:first-of-type{
         text-indent: 20px;
      }
      .el-table__row--level-0 td,
      .el-table__row--level-1 td,
      .el-table__row--level-2 td,
      .el-table__row--level-3 td{
         text-indent: 0!important;
      }
   }
</style>
